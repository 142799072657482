import {
  Alert,
  Button,
  Cascader,
  Divider,
  Form,
  Input,
  InputNumber,
  Space,
  Tooltip,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import "./forms.css";
import { editSubscriptionPlan } from "../../hooks/mutations/subscriptionPlan";
import { useMutation, useQuery } from "react-query";
import { getAllFeatures } from "../../configurations/firebaseConfig";
let index = 0;

const NewPlanEdit = ({ selectedPlan, refetchAllSubscriptions }) => {
  const [form] = Form.useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [items, setItems] = useState([]);
  const inputRef = useRef(null);
  const [featureName, setFeatureName] = useState("");
  const onFeatureNameChange = (event) => {
    setFeatureName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, featureName || `New item ${index++}`]);
    setFeatureName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const {
    mutate: editPlan,
    isLoading,
    isSuccess,
  } = useMutation(({ name, description, features }) =>
    editSubscriptionPlan({
      id: selectedPlan ? selectedPlan.id : "",
      name,
      description,
      features,
      setSuccessMessage,
      setErrorMessage,
      form,
    })
  );

  useEffect(() => {
    if (selectedPlan) {
      form.setFieldsValue({
        name: selectedPlan.name,
        description: selectedPlan.description,
        features:
          selectedPlan.features &&
          selectedPlan.features.map((feature) => [feature.name]),
        price: selectedPlan.price,
      });
    }
  }, [selectedPlan]);

  const { isLoading: isFeaturesLoading } = useQuery(
    "fetchFeaturesEdit",
    async () => {
      let result = await getAllFeatures();
      if (result.data) {
        result = JSON.parse(result.data);
        setItems(result.map((ft) => [ft.name]));
      }
      return result;
    }
  );

  useEffect(() => {
    if (isSuccess) {
      refetchAllSubscriptions();
    }
  }, [isSuccess]);

  return (
    <div>
      <p>
        {errorMessage && <Alert type="error" message={errorMessage} />}
        {successMessage && <Alert type="success" message={successMessage} />}
      </p>
      <Form className="form" form={form} onFinish={editPlan}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please provide a plan name" }]}
          style={{ display: "inline-block", width: "calc(50%)" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          wrapperCol={{ offset: 1 }}
          labelCol={{ offset: 1 }}
          rules={[
            {
              required: true,
              message: "Please provide a plan description",
            },
          ]}
          style={{
            display: "inline-block",
            width: "calc(50%)",
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Features"
          name="features"
          rules={[
            {
              required: true,
              message: "Please select a feature",
            },
          ]}
          style={{ display: "inline-block", width: "100%" }}
        >
          <Cascader
            multiple
            options={items.map((item) => ({
              label: item,
              value: item,
            }))}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: "8px 0",
                  }}
                />
                <Space
                  style={{
                    padding: "0 8px 4px",
                  }}
                >
                  <Input
                    disabled={isFeaturesLoading ? true : false}
                    placeholder={
                      isFeaturesLoading
                        ? "Loading Features..."
                        : "Add new features"
                    }
                    ref={inputRef}
                    value={featureName}
                    onChange={onFeatureNameChange}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    className="add-extra-features-button"
                    onClick={addItem}
                  >
                    Add
                  </Button>
                </Space>
              </>
            )}
            showSearch={true}
            showCheckedStrategy={true}
            placeholder={"Select plan features"}
            style={{ width: "calc(100%)" }}
          />
        </Form.Item>
        <Form.Item
          label={
            <div>
              Price/month (USD){" "}
              <Tooltip
                overlayClassName="custom-tooltip"
                placement="right"
                title="The amount is not editable post subscription plan creation! To change the amount, you will need to cancel this plan and create a new one."
                color={"red"}
              >
                <InfoCircleOutlined style={{ color: "red", fontSize: 13 }} />
              </Tooltip>
            </div>
          }
          name="price"
          rules={[{ required: true, message: "Please add a price" }]}
          style={{ display: "inline-block", width: "calc(50%)" }}
        >
          <InputNumber style={{ width: "60%" }} disabled />
        </Form.Item>
        <Form.Item
          style={{
            width: "calc(100%)",
            marginBottom: -5,
          }}
        >
          <Button
            style={{ width: "100%" }}
            className="login-button"
            htmlType="submit"
            loading={isLoading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { NewPlanEdit };
