import playstore from '../../images/playstore.png';
import appstore from '../../images/appstore.png';
import { useState } from 'react';
import { Spin } from 'antd';
import { useQuery } from 'react-query';

const DeviceDetection = () => {
    const [clicked, setClicked] = useState(false);

    useQuery("setToFalseOnWindowFocus", () => {
        setClicked(false);
    }, {
        refetchOnWindowFocus: true
    })

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <div style={{ height: 300, width: 300 }}>
                <lottie-player
                    src="https://firebasestorage.googleapis.com/v0/b/intel-construction.appspot.com/o/gifs%2Fnot-accessible-on-phone.json?alt=media&token=66514fcf-9470-4acd-a50d-49360eb9ec9e&_gl=1*1oc8n10*_ga*MTcyMTQ0NjI3MC4xNjc5OTk4MzUw*_ga_CW55HF8NVT*MTY5ODczNzY3Ni41NS4xLjE2OTg3Mzg1NDUuOC4wLjA."
                    background="transparent"
                    speed="1.5"
                    loop
                    autoplay
                ></lottie-player>
            </div>
            <div
                className="access-denied-text"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                }}
            >
                <span style={{ fontSize: 16, fontWeight: "bold", marginBottom: 20 }}>
                    🌟 Hey there! Don't worry! 🌟
                </span>
                <span>Our platform is optimized for laptop/desktop access.</span>
                <span>
                    To enjoy the full experience of the Charis Analytics platform, please use a laptop or
                    desktop computer.
                </span>
                {clicked ? <div style={{ height: '100%', display: 'flex', justifyContent: 'center', marginTop: 30 }}><Spin size={10} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} /></div> :
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, justifyContent: 'center' }}>
                        <a href="https://play.google.com/store/apps/details?id=com.charisanalytics" style={{ flex: 0.5, }} onClick={() => setClicked(true)} target="_blank" rel="noopener noreferrer">

                            <img src={playstore} style={{ height: '100%', width: 150, objectFit: 'contain' }} />

                        </a>
                        <a href="https://apps.apple.com/us/app/charis-analytics/id6503086732" style={{ flex: 0.5 }} onClick={() => setClicked(true)} target="_blank" rel="noopener noreferrer">

                            <img src={appstore} style={{ height: '100%', width: 150, objectFit: 'contain' }} />

                        </a>
                    </div>}
            </div>
        </div>
    )
}

export { DeviceDetection };