import {
  createSubscriptionPlan,
  getSubscriptionPlanDetails,
  updateSubscriptionPlan,
} from "../../configurations/firebaseConfig";

const submitSubscriptionPlan = async ({
  name,
  description,
  features,
  price,
  setSuccessMessage,
  setErrorMessage,
  form,
}) => {
  try {
    setSuccessMessage("");
    setErrorMessage("");
    if (features.length) {
      features = features.map((ft) => ({ name: ft[0] }));
    }
    await createSubscriptionPlan({ name, description, features, price });
    setSuccessMessage("Subscription plan created successfully.");
    form.resetFields();
  } catch (error) {
    setErrorMessage("Error saving Subscription Plan");
    throw error;
  }
};

const editSubscriptionPlan = async ({
  id,
  name,
  description,
  features,
  setSuccessMessage,
  setErrorMessage,
  form,
}) => {
  try {
    setSuccessMessage("");
    setErrorMessage("");
    if (features.length) {
      features = features.map((ft) => ({ name: ft[0] }));
    }
    await updateSubscriptionPlan({ id, name, description, features });
    setSuccessMessage("Subscription plan updated successfully.");
    form.resetFields();
  } catch (error) {
    setErrorMessage("Error saving Subscription Plan");
    throw error;
  }
};

const fetchSubPlanDetails = async ({ subscriptionPlanId }) => {
  const response = await getSubscriptionPlanDetails({ subscriptionPlanId });
  return response;
};

export { submitSubscriptionPlan, fetchSubPlanDetails, editSubscriptionPlan };
