import { Button, Col, Row } from "antd";

const ActionConfirmation = ({ message, onConfirm, onCancel, loading }) => {
  return (
    <div className="delete-alert-container">
      <div>
        <span>{message}</span>
        <Row style={{ marginTop: 20 }}>
          <Col span={12}>
            <Button
              className="login-button"
              style={{ width: "60%" }}
              onClick={onConfirm}
              loading={loading}
            >
              Yes
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className="delete-alert-button"
              style={{ width: "60%" }}
              onClick={onCancel}
            >
              No
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export { ActionConfirmation };
