import { Card, Col, Divider, Row, Skeleton } from "antd";
import React from "react";

const TransactionCardLoading = () => {
  return (
    <Card
      style={{
        marginRight: "50px",
        flex: 0.3,
      }}
      className="plan-card"
    >
      <Row>
        <Col span={18}>
          <p style={{ fontSize: 20 }}>
            <strong>
              <Skeleton.Button
                active
                size={20}
                style={{ marginTop: 5, borderRadius: 4 }}
              />
            </strong>
          </p>
        </Col>
        <Col
          span={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "relative",
          }}
        >
          <p>
            <Skeleton.Button
              active
              size={20}
              style={{ marginTop: 5, borderRadius: 4 }}
            />
          </p>
        </Col>
      </Row>
      <div style={{ marginTop: "-25px" }}>
        <Divider />
      </div>
      <p>
        <strong>
          <Skeleton.Input
            active
            size={15}
            style={{ marginTop: 5, borderRadius: 4 }}
          />
        </strong>
      </p>
      <div
        style={{
          height: "80px",
          overflowY: "auto",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <Skeleton.Input
          active
          size={12}
          block
          style={{ marginTop: 5, borderRadius: 4, width: "100%" }}
        />
        <Skeleton.Input
          active
          size={12}
          block
          style={{ marginTop: 10, borderRadius: 4, width: "100%" }}
        />
      </div>
      <Divider />
      <Row style={{ marginBottom: 10 }}>
        <Col span={16}>
          <Skeleton.Button active size={20} style={{ borderRadius: 4 }} />
        </Col>
        <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Skeleton.Button active size={20} style={{ borderRadius: 4 }} />
        </Col>
      </Row>
    </Card>
  );
};

export { TransactionCardLoading };
