import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const SeniorDashboard = lazy(() =>
  import("../components/dashboard").then((modules) => {
    return { default: modules.SeniorDashboard };
  })
);
const DeliverableContents = lazy(() =>
  import("../views/organisationShared/DeliverableContents").then((modules) => {
    return { default: modules.DeliverableContents };
  })
);
const ProtectedView = lazy(() =>
  import("../views/ProtectedView").then((modules) => {
    return { default: modules.ProtectedView };
  })
);
const ProjectDeliverables = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.ProjectDeliverables };
  })
);
const OrganisationAccounts = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.OrganisationAccounts };
  })
);
const OrganisationSubscriptions = lazy(() =>
  import("../views/senior").then((modules) => {
    return { default: modules.OrganisationSubscriptions };
  })
);
const OrganisationSubscriptionsView = lazy(() =>
  import("../views/senior").then((modules) => {
    return { default: modules.OrganisationSubscriptionsView };
  })
);
const SubscriptionsPaymentView = lazy(() =>
  import("../views/payments").then((modules) => {
    return { default: modules.SubscriptionsPaymentView };
  })
);
const SubscriptionsPayment = lazy(() =>
  import("../views/payments").then((modules) => {
    return { default: modules.SubscriptionsPayment };
  })
);

const ProjectDeliverablesView = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.ProjectDeliverablesView };
  })
);
const SeniorLanding = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.SeniorLanding };
  })
);
const OrganisationLevelCesiumTimeline = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.OrganisationLevelCesiumTimeline };
  })
);
const OrganisationScanLevel = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.OrganisationScanLevel };
  })
);

const OrganisaitonTimelapse = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.OrganisaitonTimelapse };
  })
);
const Support = lazy(() =>
  import("../views/index.js").then((modules) => {
    return { default: modules.Support };
  })
);
const SeniorRoutes = () => {
  return (
    <Routes>
      <Route
        path="/seniorDashboard"
        element={
          <ProtectedView
            element={
              <Suspense>
                <SeniorDashboard />
              </Suspense>
            }
          />
        }
      >
        <Route
          index={true}
          element={
            <ProtectedView
              element={
                <Suspense>
                  <SeniorLanding />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="accounts"
          element={
            <ProtectedView
              element={
                <Suspense>
                  <OrganisationAccounts />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="subscriptionPlans"
          element={
            <ProtectedView
              element={
                <Suspense>
                  <OrganisationSubscriptionsView />
                </Suspense>
              }
            />
          }
        >
          <Route
            index={true}
            element={
              <ProtectedView
                element={
                  <Suspense>
                    <OrganisationSubscriptions />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path=":subPlanId/payments"
            element={
              <ProtectedView
                element={
                  <Suspense>
                    <SubscriptionsPaymentView />
                  </Suspense>
                }
              />
            }
          >
            <Route
              path="new"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <SubscriptionsPayment />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path=":paymentId"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <SubscriptionsPayment />
                    </Suspense>
                  }
                />
              }
            />
          </Route>
        </Route>
        <Route path="projects/:projectId">
          <Route
            path="deliverables"
            element={
              <ProtectedView
                element={
                  <Suspense>
                    <ProjectDeliverablesView />
                  </Suspense>
                }
              />
            }
          >
            <Route
              index={true}
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <ProjectDeliverables />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path=":deliverableId/:contentsPath"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <DeliverableContents />
                    </Suspense>
                  }
                />
              }
            />
          </Route>
          <Route path="timeline">
            <Route
              path="2D"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <OrganisationLevelCesiumTimeline />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="3D"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <OrganisationLevelCesiumTimeline />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="3DScan"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <OrganisationScanLevel />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="timelapse"
              element={
                <ProtectedView
                  element={
                    <Suspense>
                      <OrganisaitonTimelapse />
                    </Suspense>
                  }
                />
              }
            />
          </Route>
        </Route>
        <Route
          path="support"
          element={
            <ProtectedView
              element={
                <Suspense>
                  <Support />
                </Suspense>
              }
            />
          }
        />
      </Route>
    </Routes>
  );
};

export { SeniorRoutes };
