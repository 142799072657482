import { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Drawer, Form, Input, Menu, Row } from "antd";
import { Dashboard } from "./Dashboard";
import {
  ProjectOutlined,
  UsergroupAddOutlined,
  SearchOutlined,
  ArrowLeftOutlined,
  EditOutlined,
  CameraFilled,
  PoundOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { JoyContext, SingleOrganisationContext } from "../../contexts";
import { useMutation } from "react-query";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../configurations/firebaseConfig";
import { updateOrganisationProfile } from "../../queries/organisations";

const { SubMenu } = Menu;

const SeniorDashboard = () => {
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  const { projects, organisationData, fetchOrganisationData } = useContext(
    SingleOrganisationContext
  );
  const { seniorDashboardSteps, setSteps, setEnabled, setRunningGuide } =
    useContext(JoyContext);
  const [searchableProjects, setSearchableProjects] = useState();
  const [allowEdit, setAllowEdit] = useState(false);
  const [showEditInfoModal, setShowEditInfoModal] = useState(false);
  const handleEditInfoClose = () => setShowEditInfoModal(false);
  const handleEditInfoShow = () => setShowEditInfoModal(true);
  const [currentImage, setCurrentImage] = useState(
    "https://firebasestorage.googleapis.com/v0/b/intel-construction.appspot.com/o/icons%2Fuser-icon.jpg?alt=media&token=de1df897-6f5d-4c78-aeb6-f5a87afb4414"
  );
  const [currentImageUrl, setCurrentImageUrl] = useState(
    "https://firebasestorage.googleapis.com/v0/b/intel-construction.appspot.com/o/icons%2Fuser-icon.jpg?alt=media&token=de1df897-6f5d-4c78-aeb6-f5a87afb4414"
  );

  const setOrganisationName = () => {
    form.setFieldsValue({
      organisationName: organisationData.name,
    });
  };

  const changeImage = (file) => {
    const fr = new FileReader();
    fr.onload = function (e) {
      document.getElementById("organisationImage").src = this.result;
      setCurrentImageUrl(this.result);
    };
    fr.readAsDataURL(file);
  };

  useEffect(() => {
    if (projects && projects.length) {
      const onBoardingPages = JSON.parse(
        localStorage.getItem("onBoadingPages")
      );
      setEnabled(false);
      if (
        pathname.endsWith("seniorDashboard") &&
        (!onBoardingPages || !onBoardingPages.dashboardIntroduced)
      ) {
        setSteps(seniorDashboardSteps);
        setEnabled(true);
        setRunningGuide("dashboardIntroduced");
      }

      setSearchableProjects(projects);
    }
  }, [projects, pathname]);

  useEffect(() => {
    if (organisationData) {
      if (organisationData.name) {
        setOrganisationName();
      }
      if (organisationData.profilePhoto) {
        setCurrentImage(organisationData.profilePhoto);
        setCurrentImageUrl(organisationData.profilePhoto);
      }
    }
  }, [organisationData]);

  const handleSubmit = async ({ organisationName }) => {
    if (organisationName === "" && organisationName.length < 3) {
      throw new Error("First name must be atleast 3 characters long");
    } else {
      try {
        if (organisationData) {
          const newData = {};
          if (typeof currentImage === "object") {
            let firebaseStoragePath = `/profiles/organisations/${organisationData.id}`;
            const fileReference = new ref(storage, `${firebaseStoragePath}`);
            const response = await new uploadBytes(fileReference, currentImage);
            const downloadURL = await getDownloadURL(response.ref);
            newData.profilePhoto = downloadURL;
          }
          if (organisationData.name !== organisationName) {
            newData.name = organisationName;
          }
          if (Object.keys(newData).length) {
            await updateOrganisationProfile({
              organisationId: organisationData.id,
              ...newData,
            });
          }
          fetchOrganisationData(organisationData.id);
          handleEditInfoClose();
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const { isLoading, mutate: updateUserInfo } = useMutation(
    ({ organisationName }) => handleSubmit({ organisationName })
  );

  return (
    <Dashboard
      organizationInterface={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className="organization-profile-icon">
            <Menu theme="dark" style={{ width: "100%" }}>
              <Menu.Item
                onClick={() => {
                  handleEditInfoShow();
                }}
                style={{
                  height: 60,
                  cursor: "default",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: 8,
                }}
                icon={
                  <span
                    id="fourth-step"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginLeft: "10px",
                    }}
                  >
                    {organisationData && (
                      <img
                        src={
                          organisationData.profilePhoto
                            ? organisationData.profilePhoto
                            : "https://firebasestorage.googleapis.com/v0/b/intel-construction.appspot.com/o/icons%2Fuser-icon.jpg?alt=media&token=de1df897-6f5d-4c78-aeb6-f5a87afb4414"
                        }
                        alt=""
                        className="sidebar-image"
                        style={{
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </span>
                }
              ></Menu.Item>
            </Menu>
          </div>
          {organisationData && organisationData.name && (
            <div className="organization-profile-headers">
              <h5>{organisationData.name}</h5>
            </div>
          )}
        </div>
      }
      homeLink={"/seniorDashboard"}
      menuInterface={
        <Fragment>
          <SubMenu
            key="sub3"
            icon={<ProjectOutlined />}
            title="Projects"
            id="first-step"
          >
            <div className="client-list">
              <input
                type="file"
                id="organisationProfilePhoto"
                onChange={(e) => {
                  if (e.target.files[0] !== undefined) {
                    changeImage(e.target.files[0]);
                    setCurrentImage(e.target.files[0]);
                  }
                }}
                style={{ display: "none" }}
                accept="image/png, image/jpeg, image/jpg, image/bmp"
              />
              <Form>
                <Form.Item className="client-list-form">
                  <Input
                    prefix={<SearchOutlined style={{ color: "lightgray" }} />}
                    placeholder="search projects"
                    size="small"
                    className="search-input"
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setSearchableProjects(projects);
                      } else {
                        setSearchableProjects(
                          projects.filter((el) =>
                            el.name
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          )
                        );
                      }
                    }}
                  />
                </Form.Item>
              </Form>

              <div className="scrolllist-clients">
                {searchableProjects && searchableProjects.length > 0 ? (
                  searchableProjects.map((project) => (
                    <Link
                      key={project.id}
                      to={`/seniorDashboard/projects/${project.id}/deliverables`}
                      id="project-names"
                    >
                      <span title={project.name}>{project.name}</span>
                    </Link>
                  ))
                ) : (
                  <p>No projects were found</p>
                )}
              </div>
            </div>
          </SubMenu>
          <Menu.Item key="3" icon={<UsergroupAddOutlined />} id="second-step">
            <Link
              to="/seniorDashboard/accounts"
              style={{ textDecoration: "none" }}
            >
              Accounts
            </Link>
          </Menu.Item>
          {organisationData && organisationData.name === "BRIAN" && (
            <Menu.Item key="4" icon={<PoundOutlined />}>
              <Link
                to="/seniorDashboard/subscriptionPlans"
                style={{ textDecoration: "none" }}
              >
                Plans
              </Link>
            </Menu.Item>
          )}
          <Drawer
            placement={"left"}
            width={250}
            closable={false}
            maskClosable={false}
            open={showEditInfoModal}
            className="edit-organisation-container"
          >
            {organisationData && (
              <div className="edit-organisation-profile">
                <Form form={form} onFinish={updateUserInfo}>
                  <div className="edit-organisation-profile-header">
                    <Row
                      style={{
                        position: "absolute",
                        bottom: 5,
                        width: "90%",
                      }}
                    >
                      <Col span={4}>
                        <ArrowLeftOutlined
                          onClick={() => {
                            handleEditInfoClose();
                          }}
                        />
                      </Col>
                      <Col
                        span={20}
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Organisation's Profile
                      </Col>
                    </Row>
                  </div>
                  <div className="edit-organisation-profile-body">
                    <div className="another-div">
                      <div className="organisation-photo-container">
                        <img
                          id={"organisationImage"}
                          alt=""
                          src={currentImageUrl}
                          style={{
                            height: 200,
                            width: 200,
                            borderRadius: 100,
                            objectFit: "cover",
                            boxShadow: "0 0 0 1px lightgrey",
                          }}
                        />
                      </div>
                      <div
                        className="hover-photo"
                        onClick={() => {
                          const imageFileInputElement = document.getElementById(
                            "organisationProfilePhoto"
                          );
                          imageFileInputElement?.click();
                        }}
                      >
                        <CameraFilled /> Change Photo
                      </div>
                    </div>
                  </div>
                  <div style={{ backgroundColor: "white", padding: 10 }}>
                    <p style={{ fontSize: 12, color: "#242063" }}>
                      Organization Name
                    </p>

                    <Form.Item
                      name="organisationName"
                      style={{
                        display: "inline-block",
                        width: "calc(100%)",
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please add your Organization Name",
                        },
                        {
                          pattern: new RegExp(/^[a-zA-Z ]+$/i),
                          message: "no special characters allowed",
                        },
                        {
                          message: "should be atleast 3 characters long",
                          validator: (_, value) => {
                            if (
                              value &&
                              value.length &&
                              String(value).trim().length >= 3
                            ) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject("Name not valid");
                            }
                          },
                        },
                      ]}
                    >
                      <Input
                        suffix={
                          !allowEdit ? (
                            <EditOutlined
                              className="edit-profile-icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setAllowEdit(true);
                              }}
                            />
                          ) : (
                            <CloseOutlined
                              className="edit-profile-icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setAllowEdit(false);
                                setOrganisationName();
                              }}
                            />
                          )
                        }
                        style={{ borderRadius: 4 }}
                        disabled={!allowEdit ? true : false}
                      />
                    </Form.Item>
                    <div style={{ padding: 10 }}>
                      <span style={{ fontSize: 12, color: "#8696a0" }}>
                        This is not your username or pin. This name will be
                        visible to all organisation members
                      </span>
                    </div>
                  </div>
                  <div className="edit-organisation-profile-footer">
                    <Button
                      className="login-button"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Drawer>
        </Fragment>
      }
    />
  );
};

export { SeniorDashboard };
