import { Card, Col, Divider, Row, Skeleton } from "antd";
import React from "react";

const PaymentOfficerLoading = () => {
  return (
    <Card
      className="plan-card"
      style={{
        flex: 0.3,
      }}
    >
      <p style={{ fontSize: 20 }}>
        <Skeleton.Input
          active
          size={20}
          style={{ marginTop: 5, borderRadius: 4 }}
        />
      </p>
      <div style={{ marginTop: "-5px" }}>
        <Divider />
      </div>
      <p>
        <Skeleton.Input
          active
          size={15}
          style={{ marginTop: 5, borderRadius: 4 }}
        />
      </p>
      <Row align="middle">
        <Col>
          <Skeleton.Avatar
            active
            style={{
              borderRadius: "50%",
              overflow: "hidden",
              width: "80px",
              height: "80px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              border: "2px solid #f0f0f0", // Light gray border
              backgroundColor: "white",
              marginRight: "16px",
            }}
          />
        </Col>
        <Col flex="auto" style={{ display: "flex", flexDirection: "column" }}>
          <Skeleton.Input active size={15} style={{ borderRadius: 4 }} />
          <Skeleton.Button
            active
            size={13}
            style={{ marginTop: 5, borderRadius: 4 }}
          />
          <div>
            <Skeleton.Input
              active
              size={13}
              style={{ marginTop: 5, borderRadius: 4 }}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export { PaymentOfficerLoading };
