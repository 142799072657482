import { Card, Col, Divider, Row, Skeleton } from "antd";
import React from "react";

const PaymentCardLoading = () => {
  return (
    <Card
      title={
        <div>
          <Skeleton.Input
            active
            size={20}
            style={{ marginTop: 10, borderRadius: 4 }}
          />
        </div>
      }
      className="plan-card"
    >
      <p>
        <Skeleton.Input
          active
          size={15}
          style={{ marginTop: 10, borderRadius: 4 }}
        />
      </p>
      <div style={{ marginBottom: 10 }}>
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <Skeleton.Input
            active
            block
            size={10}
            style={{ marginTop: 10, borderRadius: 4, width: "100%" }}
          />
        </div>
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <Skeleton.Input
            active
            block
            size={10}
            style={{ marginTop: 20, borderRadius: 4, width: "100%" }}
          />
        </div>
      </div>
      <Divider />
      <Row style={{ marginBottom: 30 }}>
        <Col span={16}>
          <strong>
            <Skeleton.Button
              active
              size={15}
              style={{ marginTop: 10, borderRadius: 4 }}
            />
          </strong>
        </Col>
        <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
          <strong>
            <Skeleton.Button
              active
              size={15}
              style={{ marginTop: 10, borderRadius: 4 }}
            />
          </strong>
        </Col>
      </Row>
      <Skeleton.Input
        active
        size={10}
        block
        style={{ marginTop: 10, borderRadius: 4, width: "100%" }}
      />
      <Skeleton.Input
        active
        size={10}
        style={{ marginTop: 10, borderRadius: 4 }}
      />
      <div style={{ textAlign: "center", margin: 20 }}>
        <strong>
          <Skeleton.Input
            active
            size={20}
            style={{ marginTop: 10, borderRadius: 4 }}
          />
        </strong>
      </div>
    </Card>
  );
};

export { PaymentCardLoading };
