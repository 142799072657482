import { Skeleton } from 'antd';

const SubscriptionPlansLoading = () => {
    const size = [1, 2, 3];
    return (
        <div>
            <div className="subscription-card-container" style={{ padding: 0 }}>
                {size.map((el) => (
                    <div className="subscription-card" key={String(el)}>
                        <h2><Skeleton.Input
                            active
                            size={20}
                            style={{ marginTop: 10, borderRadius: 4 }}
                        /></h2>
                        <h4><Skeleton.Input
                            active
                            size={15}
                            style={{ marginTop: 5, borderRadius: 4 }}
                        /></h4>
                        <div
                            style={{
                                height: "120px",
                                overflowY: "auto",
                                borderBottom: "0.1px solid lightgrey",
                            }}
                        >
                            <div style={{ marginBottom: 5, marginTop: 10, lineHeight: 2 }}>

                                <Skeleton.Input
                                    active
                                    size={10}
                                    block
                                    style={{ borderRadius: 4, width: '100%' }}
                                />
                                <Skeleton.Input
                                    active
                                    size={10}
                                    block
                                    style={{ borderRadius: 4, width: '100%' }}
                                />
                                <Skeleton.Input
                                    active
                                    size={10}
                                    block
                                    style={{ borderRadius: 4, width: '100%' }}
                                />
                            </div>
                        </div>
                        <br />
                        <Skeleton.Input
                            active
                            size={20}
                            style={{ borderRadius: 4, marginBottom: 20, marginTop: 10 }}
                        />
                        <Skeleton.Input
                            active
                            size={14}
                            style={{ borderRadius: 4 }}
                        />
                        <Skeleton.Input
                            active
                            size={32}
                            block
                            style={{ marginTop: 15, borderRadius: 4, width: "100%" }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export { SubscriptionPlansLoading }