import { Suspense, lazy } from "react";
import { Ion } from "cesium";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  AuthRoutes,
  DataProcessorRoutes,
  SeniorRoutes,
  SubordinateRoutes,
  SupervisorRoutes,
} from "./routes";
import { AuthProvider, OrganisationsProvider } from "./contexts";
import { SingleOrganisationProvider } from "./contexts/SingleOrganisationContext";
import { JoyProvider } from "./contexts";
import { useEffect, useState } from "react";

Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_DEFAULT_ACCESS_TOKEN;

const DeviceDetection = lazy(() =>
  import("./components/deviceDetection").then((modules) => {
    return { default: modules.DeviceDetection };
  })
);
const App = () => {
  const queryClient = new QueryClient();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(
      navigator.userAgent
    );
    setIsMobile(isMobileDevice);
  }, []);

  return isMobile ? (
    <Suspense>
      <QueryClientProvider client={queryClient}>
        <DeviceDetection />
      </QueryClientProvider>
    </Suspense>
  ) : (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          <AuthRoutes />
          <JoyProvider>
            <OrganisationsProvider>
              <SupervisorRoutes />
              <DataProcessorRoutes />
            </OrganisationsProvider>
            <SingleOrganisationProvider>
              <SeniorRoutes />
              <SubordinateRoutes />
            </SingleOrganisationProvider>
          </JoyProvider>
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
